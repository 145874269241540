.maindivofmission {
  padding: 0;
}

.firstchildmission {
  background-color: #112038;
  padding: 6rem;
  color: white;
}

.headingsmission {
  font-size: 3.2rem;
  margin-bottom: 4rem;
}

.textofvission {
  font-weight: 100;
  color: rgb(226, 228, 230);
  text-align: left;
}
.marginbottomclass {
  margin-bottom: 1rem;
}

@media (max-width: 450px) {
  .maindivofmission {
    padding: 0;
  }

  .firstchildmission {
    background-color: #112038;
    padding: 1.5rem;
    color: white;
  }
  .textofvission {
    font-weight: 100;
    color: rgb(226, 228, 230);
    text-align: center;
  }
}
