.bodytwo {
  background: linear-gradient(150deg, #2e3036 60%, #172034 60%);
  padding-bottom: 2rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.centro {
  display: flex;
  justify-content: end;
  flex-direction: column;
  margin-top: 1.25rem;
  align-items: center;
}
.maq {
  color: white;
  font-size: 3rem;
  font-weight: 700;
}
.maqhol {
  padding-left: 5rem;
  margin-top: 5rem;
  padding-bottom: 2rem;
}
.retro {
  border-radius: 1rem;
  width: 19rem;
  height: 13rem;
}
.retrohol {
  margin-top: 2rem;
}

.excava {
  color: white;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  padding-top: 1rem;
}

.imgclassofmaqua {
  width: 90%;
}

.image-containerp {
  background-image: url("/src/image/MARTILLO\ SWICCH.png"); /* The image used */
  background-position: center; /* Center the image */
  width: 19rem;
  border-radius: 1rem;
  height: 13rem;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.image-containerq {
  background-image: url("/src/image/TRITURADORA.png"); /* The image used */
  background-position: center; /* Center the image */
  width: 19rem;
  border-radius: 1rem;
  height: 13rem;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.image-containerr {
  background-image: url("/src/image/CRIBA\ 3.png"); /* The image used */
  background-position: center; /* Center the image */
  width: 19rem;
  border-radius: 1rem;
  height: 13rem;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.image-containero {
  background-image: url("/src/image/PINZA.png"); /* The image used */
  background-position: center; /* Center the image */
  width: 18rem;
  border-radius: 1rem;
  height: 12rem;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.image-container4 {
  background-image: url("/src/image/Tractor\ Bulldozer\ Komatsu\ D65PX.png"); /* The image used */
  background-position: center; /* Center the image */
  width: 19rem;
  border-radius: 1rem;
  height: 13rem;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.image-container5 {
  background-image: url("/src/image/Cargador\ Frontal\ Komatsu\ WA320-6.jpeg"); /* The image used */
  background-position: center; /* Center the image */
  width: 19rem;
  border-radius: 1rem;
  height: 13rem;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

@media screen and (max-width: 1024px) {
  .image-containerp {
    background-image: url("/src/image/MARTILLO\ SWICCH.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containerq {
    background-image: url("/src/image/TRITURADORA.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containerr {
    background-image: url("/src/image/CRIBA\ 3.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containero {
    background-image: url("/src/image/PINZA.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-container4 {
    background-image: url("/src/image/Tractor\ Bulldozer\ Komatsu\ D65PX.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-container5 {
    background-image: url("/src/image/Cargador\ Frontal\ Komatsu\ WA320-6.jpeg"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }

  .retro {
    border-radius: 1rem;
    width: 17rem;
    height: 13rem;
  }
  .excava {
    color: white;
    font-size: 1rem;
    font-weight: 700;
    padding-top: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .retrohol {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .image-containerp {
    background-image: url("/src/image/MARTILLO\ SWICCH.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 19rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 13rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containerq {
    background-image: url("/src/image/TRITURADORA.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 19rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 13rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containerr {
    background-image: url("/src/image/CRIBA\ 3.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 19rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 13rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containero {
    background-image: url("/src/image/PINZA.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 19rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 13rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-container4 {
    background-image: url("/src/image/Tractor\ Bulldozer\ Komatsu\ D65PX.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 19rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 13rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-container5 {
    background-image: url("/src/image/Cargador\ Frontal\ Komatsu\ WA320-6.jpeg"); /* The image used */
    background-position: center; /* Center the image */
    width: 19rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 13rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }

  .excava {
    color: white;
    font-size: 1rem;
    text-align: center;
    font-weight: 700;
    margin-left: 1rem;
  }
  .maqhol {
    margin-left: 3.5rem;
    margin-top: 2rem;
  }

  .maq {
    color: white;
    font-size: 2rem;
    font-weight: 700;
    margin: 1rem;
  }
}
@media screen and (max-width: 375px) {
  .maqhol {
    margin-left: 2rem;
    margin-top: 2rem;
  }
  .image-containerp {
    background-image: url("/src/image/MARTILLO\ SWICCH.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containerq {
    background-image: url("/src/image/TRITURADORA.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containerr {
    background-image: url("/src/image/CRIBA\ 3.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containero {
    background-image: url("/src/image/PINZA.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-container4 {
    background-image: url("/src/image/Tractor\ Bulldozer\ Komatsu\ D65PX.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-container5 {
    background-image: url("/src/image/Cargador\ Frontal\ Komatsu\ WA320-6.jpeg"); /* The image used */
    background-position: center; /* Center the image */
    width: 17rem;
    margin-left: 4rem;
    border-radius: 1rem;
    height: 11rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
}
@media screen and (max-width: 320px) {
  .maqhol {
    margin-left: 0.5rem;
    margin-top: 2rem;
  }
  .image-containerp {
    background-image: url("/src/image/MARTILLO\ SWICCH.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 15rem;
    margin-left: 2.5rem;
    border-radius: 1rem;
    height: 9rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containerq {
    background-image: url("/src/image/TRITURADORA.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 15rem;
    margin-left: 2.5rem;
    border-radius: 1rem;
    height: 9rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containerr {
    background-image: url("/src/image/CRIBA\ 3.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 15rem;
    margin-left: 2.5rem;
    border-radius: 1rem;
    height: 9rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-containero {
    background-image: url("/src/image/PINZA.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 15rem;
    margin-left: 2.5rem;
    border-radius: 1rem;
    height: 9rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-container4 {
    background-image: url("/src/image/Tractor\ Bulldozer\ Komatsu\ D65PX.png"); /* The image used */
    background-position: center; /* Center the image */
    width: 15rem;
    margin-left: 2.5rem;
    border-radius: 1rem;
    height: 9rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .image-container5 {
    background-image: url("/src/image/Cargador\ Frontal\ Komatsu\ WA320-6.jpeg"); /* The image used */
    background-position: center; /* Center the image */
    width: 15rem;
    margin-left: 2.5rem;
    border-radius: 1rem;
    height: 9rem;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .excava {
    color: white;
    font-size: 1rem;
    font-weight: 700;
    padding-top: 1rem;
  }
}
