.border {
  padding-left: 4rem;
  padding-right: 4rem;
}
.divo {
  height: 2.5rem;
  width: 30rem;
  display: flex;
  justify-content: start;
  background-color: #214d80;
}

.dd13 {
  color: white;
  padding: 0.5rem;
  font-size: 1.25rem;
}
.divohol {
  width: 30rem;
}
.poten {
  font-weight: 700;
  padding: 0.5rem;
  text-align: center;
  font-size: 1.25rem;
}
.potenhol {
  display: flex;
  justify-content: space-between;
}
.gray {
  width: 19rem;
  height: 2.5rem;
  display: flex;
  justify-content: start;
  background-color: #d8d8d8;
}
.carga {
  font-weight: 700;
  font-size: 1.15rem;
  padding: 0.5rem;
  text-align: left;
  width: 23rem;
}

.gray1 {
  width: 9rem;
  height: 2.5rem;
  background-color: #d8d8d8;
}
.potenhol1 {
  display: flex;
  justify-content: space-between;
}
.ficha {
  color: white;
  font-weight: 700;
}

.btonficha {
  padding: 15px 9px 15px 9px;
  background-color: #214d80;
  border-radius: 1rem;
  border: none;
}
.btonficha1 {
  padding: 15px 9px 15px 9px;
  background-color: #214d80;
  border-radius: 1rem;
  border: none;
}
.btonmng {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}
.rowo {
  margin-top: 5rem;
}
@media screen and (max-width: 1024px) {
  .border {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .divohol {
    width: 24rem;
  }
  .divo {
    height: 2.5rem;
    width: 24rem;
    background-color: #214d80;
  }
  .poten {
    font-weight: 700;
    padding: 0.5rem;
    text-align: center;
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 768px) {
  .divohol {
    width: 30rem;
    margin-left: 6rem;
    margin-top: 4rem;
  }
  .divo {
    height: 2.5rem;
    width: 30rem;
    background-color: #214d80;
  }
}
@media screen and (max-width: 425px) {
  .divo {
    height: 2.5rem;
    width: 18rem;
    background-color: #214d80;
  }
  .divohol {
    width: 25rem;
    margin-left: -1rem;
    margin-top: 4rem;
  }
  .gray1 {
    width: 5rem;
    height: 2.5rem;
    background-color: #d8d8d8;
  }
  .btonficha {
    padding: 13px 8px 13px 8px;
    background-color: #214d80;
    border-radius: 1rem;
    border: none;
  }
  .btonficha1 {
    padding: 15px 5px 15px 5px;
    background-color: #214d80;
    border-radius: 1rem;
    border: none;
  }
  .rowo {
    margin-top: -2rem;
  }
}
@media screen and (max-width: 375px) {
  .dd13 {
    color: white;
    padding: 0.5rem;
    font-size: 1rem;
  }
  .divo {
    height: 2.5rem;
    width: 13rem;
    background-color: #214d80;
  }
  .carga {
    font-weight: 700;
    font-size: 0.85rem;
    padding: 0.5rem;
    width: 25rem;
  }
  .gray {
    width: 16.5rem;
    height: 2.5rem;
    background-color: #d8d8d8;
  }
  .divohol {
    width: 22rem;
    margin-left: -1rem;
    margin-top: 4rem;
  }
  .btonficha {
    padding: 10px 6px 10px 6px;
    background-color: #214d80;
    border-radius: 1rem;
    border: none;
  }
  .btonficha1 {
    padding: 12px 3px 12px 3px;
    background-color: #214d80;
    border-radius: 1rem;
    border: none;
  }
  .rowo {
    margin-top: -3rem;
  }
  .border {
    border: none !important;
  }
}
@media screen and (max-width: 320px) {
  .carga {
    font-weight: 700;
    font-size: 0.75rem;
    padding: 0.5rem;
    width: 23rem;
  }
  .gray {
    width: 13rem;
    height: 2.5rem;
    background-color: #d8d8d8;
  }
  .divohol {
    width: 18rem;
    margin-left: -1rem;
    margin-top: 4rem;
  }
  .gray1 {
    width: 4rem;
    height: 2.5rem;
    background-color: #d8d8d8;
  }
  .btonficha {
    padding: 8px 4px 8px 4px;
    background-color: #214d80;
    border-radius: 1rem;
    border: none;
  }
  .btonficha1 {
    padding: 8px 1px 8px 1px;
    background-color: #214d80;
    border-radius: 1rem;
    border: none;
  }
  .ficha {
    color: white;
    font-weight: 700;
    font-size: 0.85rem;
  }
  .border {
    border: none !important;
  }
  .poten {
    text-align: center;
  }
}
