.footermain {
  background-color: #112c4b;
  padding: 4rem;
  display: flex;
  margin-top: 5rem;
  justify-content: space-between;
}

.logoinfooter {
  width: 15rem;
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}

@media (max-width: 450px) {
  .footermain {
    background-color: #112c4b;
    padding: 4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
@media (max-width: 450px) {
  .contactoffoot {
    font-size: 0.85rem;
  }
}
