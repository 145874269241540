.body4 {
  background-color: #34373d;
  min-height: 100vh;
  padding-bottom: 3rem;
}
.truck {
  width: 6rem;
  height: 4rem;
  border-radius: 1rem;
}
.tranporate {
  color: white;
  padding-left: 1rem;
  margin-top: 3rem;
}
.tele11 {
  color: white;
  text-align: center;
}
.columofflex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bar {
  background-color: white;
  width: 13rem;
  margin-top: 1rem;
  height: 2.45rem;
  border-right: 12px solid #214d80;
  padding-left: 0.5rem;
}
.truckhol {
  display: flex;
  padding-top: 1.25rem;
}

.maqo {
  color: #616261;
  font-weight: 800;
  line-height: 1rem;
  padding-top: 0.75rem;
}
.barhol {
  margin-top: 5rem;
}
.gondolas {
  width: 100%;
}
.truckpic {
  margin-top: 7rem;
}
.flex {
  color: white;
  font-weight: 700;
  text-align: center;
}
.plazo {
  color: white;
  width: 8rem;
  text-align: center;
  font-weight: 300;
}
.margin {
  margin-top: 2rem;
}
.groupo1 {
  width: 6.5rem;
  margin-left: 2rem;
}

@media screen and (max-width: 1024px) {
  .flex {
    color: white;
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
  }
  .bar {
    background-color: white;
    width: 11rem;
    margin-top: 1rem;
    height: 2.45rem;
    border-right: 12px solid #214d80;
  }
}
@media screen and (max-width: 768px) {
  .barhol {
    margin-top: 5rem;
    margin-left: -2rem;
  }
  .bar {
    background-color: white;
    width: 10.75rem;
    margin-top: 1rem;
    height: 2.45rem;
    border-right: 12px solid #214d80;
  }
  .flex {
    color: white;
    font-weight: 700;
    text-align: center;
    font-size: 0.85rem;
  }
  .plazo {
    color: white;
    width: 5rem;
    text-align: center;
    font-weight: 300;
  }
}

@media screen and (max-width: 425px) {
  .barhol {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .flex {
    color: white;
    font-weight: 700;
    text-align: center;
    font-size: 1rem;
  }
  .plazo {
    color: white;
    width: 9rem;
    text-align: center;
    font-weight: 300;
  }
  .groupo1 {
    width: 4rem;
    padding-left: -3rem;
    margin-top: 1.5rem;
  }
  .bar {
    background-color: white;
    width: 14rem;
    margin-top: 1rem;
    height: 2.45rem;
    border-right: 12px solid #214d80;
  }
  .tele11 {
    color: white;
    text-align: center;
    font-size: 0.75rem;
  }

  .margin {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
}
.footer1 {
  background-color: #112c4b;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 375px) {
  .truckho {
    margin-left: 4rem;
  }
}
@media screen and (max-width: 320px) {
  .groupo1 {
    width: 6rem;
    padding-right: 2rem;
  }
  .bar {
    background-color: white;
    width: 10rem;
    margin-top: 1rem;
    height: 2.45rem;
    border-right: 12px solid #214d80;
  }
}
