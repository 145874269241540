.logoinfooter {
  width: 10rem;
}

.oneitem {
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  background-color: #112c4b;
  color: white;
}
