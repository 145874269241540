.politic {
  color: #214d80;
  font-weight: 700;
}
.politichol {
  padding-left: 4rem;
  margin-top: 4rem;
}
.bgblue {
  background-color: #182b49;
  width: 100%;
  height: 30rem;
}
.politicpic {
  width: 50rem;
  height: 15rem;
}
.politicpichol {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 4rem;
}
.dee {
  color: #fff;
  font-weight: 100;
  line-height: 2rem;
  padding-top: 1rem;
  width: 50rem;
  font-size: 1.5rem;
}
.compro {
  color: #214d80;
  font-weight: 700;
}
.comprohol {
  display: flex;
  justify-content: center;
  padding: 2rem;
}
.brown {
  background-color: #616261;
  height: 18rem;
  width: 16rem;
  padding: 1rem;
}

.brownhol {
  margin-left: 2rem;
  margin-bottom: 10rem;
}
.cali {
  color: #294f7c;
  font-weight: 700;
  text-align: center;
}
.pretar {
  color: #cecece;
  text-align: center;
  width: 11rem;
  margin-left: 1rem;
}

.textcontainergogo {
  padding: 2rem 6rem 0 6rem;
}

@media screen and (max-width: 1024px) {
  .bro {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .politicpic {
    width: 40rem;
    height: 15rem;
  }
  .dee {
    color: #fff;
    font-weight: 100;
    line-height: 2rem;
    padding-top: 1rem;
    width: 40rem;
    font-size: 1.25rem;
  }
  .brown {
    background-color: #616261;
    height: 22rem;
    width: 12.5rem;
    padding: 1rem;
  }
  .pretar {
    color: #cecece;
    text-align: center;
    width: 9rem;
    margin-left: 1rem;
  }
}
@media screen and (max-width: 430px) {
  .politicpic {
    width: 25rem;
    height: 12rem;
  }
  .dee {
    color: white;
    line-height: 1rem;
    padding-top: 1rem;
    font-size: 1rem;
    font-weight: 100;
    width: 24rem;
  }
  .brown {
    background-color: #616261;
    height: 21rem;
    width: 14rem;
    padding: 1rem;
  }
  .brownhol1 {
    display: flex;
    justify-content: center;
  }
  .brownhol2 {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
  }
  .brownhol3 {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
  }
  .pretar {
    color: #cecece;
    text-align: center;
    width: 11rem;
    margin-left: 1rem;
  }

  .textcontainergogo {
    padding: 0.5rem;
  }
}
@media screen and (max-width: 375px) {
  .dee {
    color: white;
    line-height: 1rem;
    padding-top: 1rem;
    font-size: 1rem;
    font-weight: 100;
    width: 20rem;
  }
  .politicpic {
    width: 20rem;
    height: 10rem;
  }
}
@media screen and (max-width: 320px) {
  .dee {
    color: white;
    line-height: 1rem;
    padding-top: 1rem;
    font-size: 0.75rem;
    font-weight: 100;
    width: 15rem;
  }
  .politicpic {
    width: 15rem;
    height: 6rem;
  }
  .politichol {
    padding-left: 2.5rem;
    margin-top: 4rem;
  }
  .bgblue {
    background-color: #182b49;
    width: 100%;
    height: 20rem;
  }
}
