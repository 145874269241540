.bodyy {
  background-color: #2e3036;
  padding-bottom: 1rem;
  min-height: 100vh;
}
.vehi {
  color: white;
  padding: 4rem;
  font-size: 2rem;
  font-weight: 700;
}
.bari {
  background-color: #214d80;
  height: 2.25rem;
  width: 18rem;
  margin-top: 4rem;
}
.alyy {
  margin-top: 3rem;
}
.car {
  display: flex;
}
.toyo {
  color: white;
  text-align: center;
  padding: 0.5rem;
}
.bttn {
  padding: 12px 8px 12px 8px;
  background-color: #214d80;
  border: none;
  border-radius: 1rem;
}
.teni {
  color: white;
}
.bttnhol {
  margin-top: 3rem;
}
.bari1 {
  background-color: #214d80;
  height: 2rem;
  width: 12rem;
  margin-left: -3rem;
  margin-top: 4rem;
}
.bari100 {
  background-color: #214d80;
  height: 2.5rem;
  width: 12rem;
  display: flex;
  justify-content: center;
  text-align: center;
}
.bttnhol1 {
  margin-top: 3rem;
}
.cari {
  width: 30rem;
}
.cari1 {
  width: 30rem;
}

@media screen and (max-width: 1024px) {
  .bari1hol {
    margin-left: 3rem;
  }
  .bodyy {
    background-color: #2e3036;
    padding-bottom: 1rem;
    min-height: 100vh;
  }
  .bari100 {
    background-color: #214d80;
    height: 2.5rem;
    width: 12rem;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: -3rem;
  }
}

@media screen and (max-width: 768px) {
  .cari1 {
    width: 28rem;
    margin-left: -7rem;
  }
  .cari {
    width: 25rem;
  }

  .bttnhol1 {
    margin-top: 3rem;
  }
  .barihol {
    margin-left: 4rem;
  }
}
@media screen and (max-width: 450px) {
  .bari {
    background-color: #214d80;
    height: 2.5rem;
    width: 10rem;
    margin-top: 4rem;
  }
  .toyo {
    color: white;
    text-align: center;
    padding: 0.5rem;
    font-size: 0.75rem;
  }
  .cari1 {
    width: 50rem;
  }
  .bari1 {
    background-color: #214d80;
    height: 2.5rem;
    width: 8rem;
    margin-left: -1rem;
    margin-top: 4rem;
  }

  .cari1 {
    margin-left: 1rem;
    width: 27rem;
    margin-top: 2rem;
  }
  .alli {
    margin-top: 10rem;
    margin-left: -5rem;
  }
  .bttnhol1 {
    margin-top: 3rem;
    margin-left: -2rem;
  }
  .barihol {
    margin-top: 7rem;
  }
  .cari {
    width: 40rem;
    margin-left: 4rem;
  }
  .toyo {
    color: white;
    text-align: center;
    padding: 0.5rem;
    font-weight: 700;
    font-size: 0.5rem;
  }
  .bari {
    background-color: #214d80;
    height: 2rem;
    width: 8rem;
    margin-top: 4rem;
  }
  .bari100 {
    background-color: #214d80;
    height: 1.5rem;
    width: 8rem;
    margin-top: 4rem;
  }
  .teni {
    color: white;
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 325px) {
  .cari {
    margin-left: 3rem;
  }
}
