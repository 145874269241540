.rowo {
  margin-top: 4rem;
}

.rowo1 {
  margin-top: 2rem;
}
.rowo2 {
  margin-top: 2rem;
}
.rowo3 {
  margin-top: 2rem;
}
.rowo4 {
  margin-top: 2rem;
}
