.mainimageherosection {
  display: flex;
  justify-content: center;
  background-image: url("/public/imgs/herosection.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 85vh;
}

.headingofhero {
  margin-top: 16rem;
  color: white;
  font-weight: 500;
  font-size: 6rem;
  text-align: center;
  letter-spacing: 0.1em;
  text-shadow: 1px 1px #94a0a7;
}

.headingofhero2 {
  color: white;
  font-weight: 500;
  font-size: 6rem;
  text-align: center;
  letter-spacing: 0.1em;
  text-shadow: 1px 1px #94a0a7;
}

.flexandcolumns {
  display: flex;
  justify-content: space-around;
}

.flexandcolumnschild {
  background-color: white;
  height: 14rem;
  width: 22rem;
  margin-top: -13.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgchildofflexandcolumnschild {
  width: 95%;
  border-radius: 0.1rem;
}

.HEADINGOFCHILDS {
  color: #272626;
  font-weight: 800;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 1px;
}

@media (max-width: 1450px) {
  .mainimageherosection {
    display: flex;
    justify-content: center;
    background-image: url("/public/imgs/herosection.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 120vh;
  }
  .flexandcolumnschild {
    background-color: white;
    height: 10rem;
    width: 16rem;
    margin-top: -10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgchildofflexandcolumnschild {
    width: 85%;
    border-radius: 0.1rem;
  }
}
@media (width: 1366px) {
  .mainimageherosection {
    display: flex;
    justify-content: center;
    background-image: url("/public/imgs/herosection.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 110vh;
  }

  .headingofhero {
    margin-top: 5rem;
    color: white;
    font-weight: 500;
    font-size: 6rem;
    text-align: center;
    letter-spacing: 0.1em;
    text-shadow: 1px 1px #94a0a7;
  }
}

@media (max-width: 1030px) {
  .flexandcolumnschild {
    background-color: white;
    height: 6rem;
    width: 10rem;
    margin-top: -6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgchildofflexandcolumnschild {
    width: 85%;
    border-radius: 0.1rem;
  }
  .HEADINGOFCHILDS {
    color: #272626;
    font-weight: 800;
    font-size: 0.8rem;
    text-align: center;
  }
}

@media (max-width: 770px) {
  .flexandcolumnschild {
    background-color: white;
    height: 4rem;
    width: 6rem;
    margin-top: -4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgchildofflexandcolumnschild {
    width: 90%;
    border-radius: 0.1rem;
  }
  .HEADINGOFCHILDS {
    color: #272626;
    font-weight: 800;
    font-size: 0.6rem;
    text-align: center;
  }
}

@media (max-width: 440px) {
  .mainimageherosection {
    display: flex;
    justify-content: center;
    background-image: url("/public/imgs/herosection.png");
    background-repeat: no-repeat;
    height: 125vh;
  }

  .headingofhero {
    margin-top: 16rem;
    color: white;
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
  }

  .headingofhero2 {
    color: white;
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
  }

  .HEADINGOFCHILDS {
    color: #272626;
    font-weight: 700;
    font-size: 0.5rem;
    text-align: center;
  }

  .flexandcolumnschild {
    background-color: white;
    height: 4rem;
    width: 6rem;
    margin-top: -4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgchildofflexandcolumnschild {
    width: 90%;
    border-radius: 0.1rem;
  }
}
