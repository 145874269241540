.body111 {
  background-color: #112038;
  margin-top: 3.95rem;
  padding-bottom: 5rem;
}
.noso {
  font-size: 2.75rem;
  font-weight: 700;
}

.maindivofnostro {
  color: white;
  padding: 6rem 5rem 0rem 5rem;
}
.childnoso {
  font-size: 1.75rem;
  text-align: left;
  font-weight: 400;
  margin-top: 4rem;
}
.child {
  font-weight: 700;
  margin-top: 6rem;
  font-size: 2.75rem;
}

.jl {
  width: 18rem;
}
.jl1 {
  width: 18rem;
  margin-top: -1rem;
}
.empresa1 {
  color: #c3c7cd;
  font-size: 1.5rem;
  text-align: center;
  width: 17rem;
  margin-top: -2rem;
  line-height: 1.5rem;
}
.text {
  display: flex;
  justify-content: center;
  text-align: center;
}
.imghol {
  display: flex;
  justify-content: start;
  flex-direction: column;
}
.imghol1 {
  margin-top: 17rem;
  position: absolute;
}

.columofantd {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 1024px) {
  .childnoso {
    font-size: 2rem;
    text-align: center;
    width: 58rem;
  }
  .empresa1 {
    color: #c3c7cd;
    text-align: center;
    width: 15rem;
    margin-top: -2rem;
    line-height: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .childnoso {
    font-size: 2rem;
    text-align: center;
    width: 35rem;
  }
  .imghol1 {
    margin-top: 23rem;
    position: absolute;
  }
  .empresa1 {
    color: #c3c7cd;
    text-align: center;
    width: 17rem;
    margin-top: -2rem;
    line-height: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .childnoso {
    font-size: 1rem;
    text-align: center;
    width: 17rem;
  }
  .noso {
    font-size: 2rem;
    font-weight: 700;
  }
  .child {
    font-weight: 700;

    margin-top: 4rem;
    font-size: 2rem;
  }
  .columofantd {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -2.5rem;
  }
  .empresa1 {
    color: #c3c7cd;
    text-align: center;
    width: 17rem;
    margin-top: -4rem;
    line-height: 1rem;
  }
  .imghol1 {
    margin-top: 31rem;
    position: absolute;
  }
}
@media screen and (max-width: 320px) {
  .childnoso {
    font-size: 0.75rem;
    text-align: left;
    width: 12rem;
  }
  .jl {
    width: 15rem;
  }
  .jl1 {
    width: 15rem;
    margin-top: -1rem;
  }
  .empresa1 {
    color: #c3c7cd;
    text-align: center;
    width: 14rem;
    margin-top: -3rem;
    line-height: 1rem;
  }
}
