.hope1 {
  background-image: url("/src/image/3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.venta1 {
  color: #214d80;
  font-weight: 800;
  font-size: 1.45rem;
  width: 5rem;
}
.bluebarhol1 {
  background-color: #142036;
  position: absolute;
  bottom: -2.5rem;
  z-index: 1;
  margin-top: 2rem;
  right: 0;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1440px) {
  .venta1 {
    color: #214d80;
    font-weight: 800;
    font-size: 1.45rem;
    width: 5rem;
    margin-left: -3rem;
  }
}
@media screen and (max-width: 1024px) {
  .venta1 {
    color: #214d80;
    font-weight: 800;
    font-size: 1.35rem;

    margin-left: -5rem;
  }
}
@media screen and (max-width: 425px) {
  .venta1 {
    color: #214d80;
    font-weight: 800;
    font-size: 1.35rem;

    margin-left: -3rem;
  }
}
