.venta {
  color: #214d80;
  font-weight: 800;
  width: 30rem;
  margin-top: 0.5rem;
  font-size: 1.76rem;
}
.con {
  width: 23rem;
  font-size: 1.25rem;
  text-align: center;
  margin-left: 1rem;
  font-weight: 400;
}
.hope {
  background-image: url("/src/image/Venta\ Agregados\ Petreos\ Sureste\ Mexico.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.hopehol {
  height: 75vh;
}
.hills {
  margin-top: 5rem;
}

.bluebarhol {
  background-color: #142036;
  position: absolute;
  bottom: 2rem;
  right: 0;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textbluehol {
  color: white;
  font-size: 1.2rem;
}
.tus {
  color: white;
  width: 19rem;
  text-align: center;
}
.ventahol {
  margin-left: 3rem;
}
.bton-coti {
  border: none;
  border-radius: 1rem;
  padding: 15px 12px 15px 12px;
  background-color: #112c4b;
}
.coti-hol {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.bold {
  color: white;
}
@media screen and (max-width: 1440px) {
  .venta {
    color: #214d80;
    font-weight: 800;
    width: 30rem;
    margin-left: -1rem;
    margin-top: 0.5rem;
    font-size: 1.45rem;
  }
  .con {
    width: 22rem;
    font-size: 1.25rem;
    text-align: center;
    margin-left: -2rem;
    font-weight: 400;
  }
}
@media screen and (max-width: 1300px) {
  .venta {
    color: #214d80;
    font-weight: 800;
    font-size: 1.25rem;
  }
  .con {
    width: 16rem;
    font-size: 1rem;
    text-align: center;
    margin-left: -1rem;
    font-weight: 400;
  }
}

@media screen and (max-width: 1040px) {
  .venta {
    color: #214d80;
    font-weight: 800;
    margin-left: -1rem;
    font-size: 1rem;
  }
  .con {
    width: 16rem;
    font-size: 1rem;
    text-align: center;
    margin-left: -2rem;
    font-weight: 400;
  }
}
@media screen and (max-width: 768px) {
  .ventahol {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
  .venta {
    color: #214d80;
    font-weight: 800;
    font-size: 2rem;
  }
  .con {
    width: 33rem;
    font-size: 1.5rem;
    text-align: center;
    margin-left: 1rem;
    font-weight: 400;
  }
}
@media screen and (max-width: 450px) {
  .ventahol {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .venta {
    color: #214d80;
    font-weight: 800;
    margin-left: -2rem;
    font-size: 1.15rem;
    width: 18rem;
  }
  .con {
    width: 18rem;
    font-size: 1.15rem;
    margin-left: -1.5rem;
    text-align: center;
    font-weight: 400;
  }
}

@media screen and (max-width: 375px) {
  .venta {
    color: #214d80;
    font-weight: 800;
    margin-left: -0.75rem;
    font-size: 1.25rem;
  }
  .con {
    width: 18rem;
    font-size: 1.15rem;
    margin-left: -0.5rem;
    text-align: center;
    font-weight: 400;
  }
}

@media screen and (max-width: 320px) {
  .bluebar {
    background-color: #142036;
    width: 15rem;
    height: 5rem;
  }
  .tus {
    color: white;
    width: 15rem;
    text-align: center;
    padding-top: 1rem;
  }
}
