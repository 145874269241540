.maindiv {
  background-color: white;
}
.tele1 {
  margin-top: 14rem;
}
.tele0 {
  color: #616261;
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
}
.tele00 {
  color: #616261;
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: -1rem;
  text-align: center;
}
.italic {
  padding-left: 0.25rem;
  font-size: 1.5rem;
}
.fifty {
  font-weight: 600;
  font-size: 1.5rem;

  color: #666766;
  text-align: center;
}
.maindiv2 {
  background-color: #182b49;
  width: 42rem;
  margin-top: 8rem;
  height: 27rem;
  border-radius: 1rem;
}
.contact {
  color: white;
  font-weight: 700;
  font-size: 2.75rem;
  text-align: center;
  padding-top: 1rem;
  font-family: adobe-clean sans-serif;
}
.input-field {
  width: 17rem;
  height: 2.5rem;
  border: none;

  border-radius: 0.5rem;
}
.inputhol {
  display: flex;
  padding-left: 2rem;
  margin-top: 3rem;
}
.inputhol1 {
  display: flex;
  padding-left: 2rem;
  margin-top: 1rem;
}
.holder {
  padding-left: 2rem;
}
.input-field1 {
  height: 8rem;
  width: 36rem;
  border: none;
  border-radius: 0.5rem;
}

.bton {
  border-radius: 0;
  padding: 6px 13px 5px 12px;
  background-color: white;
  border: none;
  border-radius: 1rem;
}
.batan {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.footer {
  margin-top: 5rem;
  background-color: #112c4b;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
}
.group {
  color: white;
  font-style: italic;
  font-family: "Times New Roman", Times, serif;
  padding-top: 2rem;
  padding-left: 2rem;
}
.tele {
  color: white;
  font-weight: 700;
  text-align: center;
}
.nine {
  color: white;
  margin-top: -0.5rem;
}
.holdfoot {
  display: flex;
  margin-top: 2rem;
  margin-right: 3rem;
}
.telehol {
  padding-left: 1rem;
}

@media screen and (max-width: 768px) {
  .maindiv2 {
    background-color: #182b49;
    width: 35rem;
    margin-top: 8rem;
    height: 27rem;
    border-radius: 1rem;
  }
  .input-field {
    width: 14rem;
    height: 2.5rem;
    border-radius: 0.5rem;
  }
  .input-field1 {
    height: 8rem;
    width: 30.5rem;
    border-radius: 0.5rem;
  }
  .tele0 {
    color: #616261;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
  }
  .tele00 {
    color: #616261;
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: -1rem;
    text-align: center;
  }
  .italic {
    padding-left: 0.25rem;
    font-size: 1rem;
  }
  .fifty {
    font-weight: 600;
    font-size: 1rem;

    color: #666766;
    text-align: center;
  }
}
@media screen and (max-width: 425px) {
  .reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .tele1 {
    margin-top: 2rem;
    font-size: 1.5rem;
  }
  .maindiv1 {
    margin-left: 4rem;
  }
  .tele0 {
    color: #616261;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
  }
  .tele00 {
    color: #616261;
    font-weight: 700;
    margin-top: -1rem;
    font-size: 2rem;
    text-align: center;
  }
  .italic {
    padding-left: 0.25rem;
  }
  .fifty {
    font-weight: 600;
    color: #666766;
    text-align: center;
  }
  .maindiv2 {
    background-color: #182b49;
    width: 22rem;
    margin-top: 4rem;
    margin-left: 2.5rem;
    height: 25rem;
    border-radius: 1rem;
  }
  .input-field {
    width: 8rem;
    height: 2.5rem;
    border-radius: 0.5rem;
  }
  .input-field1 {
    height: 8rem;
    width: 18.5rem;
    border-radius: 0.5rem;
  }
  .footer {
    margin-top: 5rem;
    background-color: #112c4b;
    width: 113%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
  }
  .maindiv1 {
    margin-left: -1rem;
  }
  .tele {
    color: white;
    font-weight: 700;
    text-align: center;
  }
  .nine {
    color: white;
    margin-top: -0.5rem;
  }
  .holdfoot {
    display: flex;
    margin-top: 2rem;
    margin-right: 1rem;
  }
  .telehol {
    padding-left: 0.5rem;
  }
}
@media screen and (max-width: 375px) {
  .maindiv1 {
    margin-left: 3rem;
  }
  .footer {
    margin-top: 5rem;
    background-color: #112c4b;
    width: 128%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
  }
  .maindiv2 {
    background-color: #182b49;
    width: 22rem;
    margin-top: 4rem;
    margin-left: 1rem;
    height: 27rem;
    border-radius: 1rem;
  }
}
@media screen and (max-width: 325px) {
  .maindiv1 {
    margin-left: 2rem;
  }

  .tele0 {
    color: #616261;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .footer {
    margin-top: 5rem;
    background-color: #112c4b;
    width: 150%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
  }
  .maindiv2 {
    background-color: #182b49;
    width: 16rem;
    margin-top: 4rem;
    margin-left: 2.5rem;
    height: 27rem;
    border-radius: 1rem;
  }
  .input-field {
    width: 5rem;
    height: 2.5rem;
    border-radius: 0.5rem;
  }
  .input-field1 {
    height: 8rem;
    width: 13rem;
    border-radius: 0.5rem;
  }
  .tele0 {
    color: #616261;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }
  .tele00 {
    color: #616261;
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: -1rem;
    text-align: center;
  }
  .italic {
    padding-left: 0.25rem;
  }
  .fifty {
    font-weight: 600;
    color: #666766;
    font-size: 1rem;
    text-align: center;
  }
}
