.color {
  background-color: #2e3035;
  height: 25rem;
  width: 40rem;
}
.power {
  margin-right: 1rem;
}
.color1 {
  background-color: white;
  height: 15rem;

  width: 30rem;
}
.color1hol {
  display: flex;
  justify-content: center;
  padding-top: 4rem;
}
@media screen and (max-width: 1024px) {
  .color {
    background-color: #2e3035;
    height: 25rem;
    width: 35rem;
  }
  .power {
    margin-right: -0.25rem;
  }
  .color1 {
    background-color: white;
    height: 17rem;

    width: 27rem;
  }
}
@media screen and (max-width: 768px) {
  .color {
    background-color: #2e3035;
    height: 30rem;
    width: 40rem;
  }
  .power {
    margin-right: 2rem;
  }
  .color1 {
    background-color: white;
    height: 20rem;

    width: 30rem;
  }
  .color1hol {
    display: flex;
    justify-content: center;
    padding-top: 4rem;
  }
}

@media screen and (max-width: 425px) {
  .color {
    background-color: #2e3035;
    height: 20rem;
    width: 22rem;
    margin-top: 3rem;
  }
  .power {
    margin-right: 0.75rem;
  }
  .color1 {
    background-color: white;
    height: 10rem;

    width: 15rem;
  }
}
@media screen and (max-width: 375px) {
  .color {
    background-color: #2e3035;
    height: 20rem;
    width: 20rem;
    margin-top: 4rem;
  }
  .color1 {
    background-color: white;
    height: 10rem;

    width: 15rem;
  }
}
@media screen and (max-width: 320px) {
  .color {
    background-color: #2e3035;
    height: 15rem;
    width: 15rem;
    margin-top: 4rem;
  }
  .color1 {
    background-color: white;
    height: 8rem;

    width: 13rem;
  }
}
