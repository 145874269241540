.body3 {
  background-color: white;
  display: flex;
  justify-content: center;
}
.maindiv21 {
  background-color: #182b49;
  width: 50rem;
  margin-top: 4rem;
  height: 33rem;
  border-radius: 1rem;
}
.columofcotizar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.input-container1 {
  padding-left: 2rem;
}
.input-field11 {
  height: 10rem;
  width: 22rem;
  border: none;
  border-radius: 0.5rem;
}
.input-field00 {
  width: 20rem;
  border: none;
  height: 2.5rem;
  border-radius: 0.5rem;
}
.evi {
  color: white;
  font-weight: 600;
}
.bton1 {
  border-radius: 0;
  padding: 12px 20px 0px 23px;
  background-color: #1d4545;
  border-radius: 1rem;
  border: none;
  margin-top: 3rem;
}
.batan {
  display: flex;
  justify-content: center;
}
.input-container9 {
  display: none;
}
.text {
  height: 20rem;
  width: 20rem;
}
@media screen and (max-width: 768px) {
  .maindiv21 {
    background-color: #182b49;
    width: 46rem;
    margin-top: 4rem;
    height: 33rem;
    border-radius: 1rem;
  }
}

@media screen and (max-width: 425px) {
  .maindiv21 {
    background-color: #182b49;
    width: 25rem;
    margin-top: 4rem;
    height: 30rem;
    border-radius: 1rem;
  }
  .input-field11 {
    height: 10rem;
    width: 10rem;
    border: none;
    border-radius: 0.5rem;
  }
  .input-field00 {
    width: 10rem;
    border: none;
    height: 2.5rem;
    border-radius: 0.5rem;
  }

  .input-container {
    width: 10rem;
  }
}
@media screen and (max-width: 375px) {
  .maindiv21 {
    background-color: #182b49;
    width: 20rem;
    margin-top: 4rem;
    height: 29rem;
    border-radius: 1rem;
  }
  .input-field11 {
    height: 10rem;
    width: 7rem;
    border: none;
    border-radius: 0.5rem;
  }
  .input-field00 {
    width: 7rem;
    border: none;
    height: 2.5rem;
    border-radius: 0.5rem;
  }

  .input-container {
    width: 8rem;
  }
}
