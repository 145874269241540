* {
  margin: 0;
  padding: 0;
  /* font-family: "Bebas Neue", sans-serif !important;
  font-size: 104% !important; */
}

.displayflexjstyfycenterflexcolumn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  font-size: 17px !important;
  color: #184a88 !important ;
  font-weight: 600;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: -1rem !important;
}

.mycontaiiner {
  padding-right: 4rem !important;
  padding-left: 4rem !important;

  display: flex !important;
  flex-wrap: inherit !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
  margin-bottom: 10px !important;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: white !important;
}

@media (max-width: 450px) {
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: -0.5rem !important;
  }
}
