.backgroundImageofoildrig {
  background-image: url("https://www.intechww.com/wp-content/uploads/INTECH-multivendor-DCS-integration-at-Middle-Eastern-Oilfield-inpage.jpg");
  background-size: cover;
  background-size: 100% 100%;
  background-position: center;

  min-height: 90vh !important;
}

.headingclassofoilrig {
  color: white;
  font-weight: bolder;
  width: 30rem;
  text-align: center;
  padding-top: 2rem;
}

.backgroundofoilrigcolors {
  background-color: rgba(33, 77, 128, 0.8);
  width: 50rem;
  position: absolute;
  right: 1rem;
  bottom: 0;
}

.headinginsidetextcontainerolirig {
  color: white;
  font-weight: normal;
  text-align: left;
  padding: 1rem;
}

.coti-holoilrig {
  position: absolute;
  left: 5rem;
  bottom: 0;
}

@media (max-width: 800px) {
  .backgroundImageofoildrig {
    background-image: url("https://www.intechww.com/wp-content/uploads/INTECH-multivendor-DCS-integration-at-Middle-Eastern-Oilfield-inpage.jpg");
    background-size: cover;
    background-position: center;

    min-height: 90vh !important;
  }
  .headingclassofoilrig {
    color: white;
    font-weight: bolder;
    width: 15rem;
    text-align: center;
    padding-top: 2rem;
  }

  .backgroundofoilrigcolors {
    background-color: rgba(33, 77, 128, 0.8);
    width: 30rem;
    position: absolute;
    right: 1rem;
    bottom: 0;
  }

  .headinginsidetextcontainerolirig {
    color: white;
    font-weight: normal;
    text-align: left;
    padding: 1rem;
  }

  .coti-holoilrig {
    position: absolute;
    left: 5rem;
    bottom: 0;
  }
}

@media (max-width: 450px) {
  .backgroundImageofoildrig {
    background-image: url("https://www.intechww.com/wp-content/uploads/INTECH-multivendor-DCS-integration-at-Middle-Eastern-Oilfield-inpage.jpg");
    background-size: cover;
    background-position: center;

    min-height: 90vh !important;
  }
  .headingclassofoilrig {
    color: white;
    font-weight: bolder;
    width: 20rem;
    text-align: center;
    padding-top: 2rem;
  }

  .headingclassofoilrig {
    color: white;
    font-weight: bolder;
    width: 15rem;
    text-align: center;
    padding-top: 2rem;
  }

  .backgroundofoilrigcolors {
    background-color: rgba(33, 77, 128, 0.8);
    width: 20rem;
    position: absolute;
    right: 1rem;
    bottom: 0;
  }

  .headinginsidetextcontainerolirig {
    color: white;
    font-weight: normal;
    text-align: left;
    padding: 1rem;
    font-size: 1rem;
  }

  .coti-holoilrig {
    display: none !important;
  }
}

@media (max-width: 330px) {
  .backgroundImageofoildrig {
    background-image: url("https://www.intechww.com/wp-content/uploads/INTECH-multivendor-DCS-integration-at-Middle-Eastern-Oilfield-inpage.jpg");
    background-size: cover;
    background-position: center;

    min-height: 90vh !important;
  }
  .headingclassofoilrig {
    color: white;
    font-weight: bolder;
    width: 20rem;
    text-align: center;
    padding-top: 2rem;
  }

  .headingclassofoilrig {
    color: white;
    font-weight: bolder;
    width: 15rem;
    text-align: center;
    padding-top: 2rem;
  }

  .backgroundofoilrigcolors {
    background-color: rgba(33, 77, 128, 0.8);
    width: 20rem;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .headinginsidetextcontainerolirig {
    color: white;
    font-weight: normal;
    text-align: left;
    padding: 1rem;
    font-size: 1rem;
  }
}
