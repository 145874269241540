.MARGINTOPCLASS {
  margin-top: -12.2rem;
}
.textcalssrentaequipo {
  font-weight: bolder;
  font-size: 1rem;
}

@media (max-width: 1440px) {
  .MARGINTOPCLASS {
    margin-top: -5.2rem;
  }
}

@media (max-width: 1100px) {
  .MARGINTOPCLASS {
    margin-top: -5.2rem;
  }
}

@media (max-width: 800px) {
  .MARGINTOPCLASS {
    margin-top: -5.2rem;
  }
  .textcalssrentaequipo {
    font-weight: bolder;
    font-size: 0.5rem;
  }
}

@media (max-width: 450px) {
  .MARGINTOPCLASS {
    margin-top: 0rem;
  }
  .textcalssrentaequipo {
    font-weight: bolder;
    font-size: 0.5rem;
  }
}
